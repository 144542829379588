import { SvgIcon, SvgIconProps } from '@mui/material'
import svg from '@/icons/svg/InstagramLogo.svg'

export default function InstagramLogo(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      component={svg}
      viewBox="-19.5036 -32.49725 169.0312 194.9835"
    />
  )
}
